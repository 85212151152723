import React, { Component } from 'react';
import PlatformMusicSection from './PlatformMusicSection.js'
import './PlatformBody.css';

class PlatformBody extends Component {
  render(){
    return (
      <div className = "flex flex-col dark:bg-custom-black bg-custom-white">
        <PlatformMusicSection
          address={this.props.address}
          currentSong={this.props.currentSong}
          onUserClickedConnectWallet={this.props.onUserClickedConnectWallet}
          modalShown={this.props.modalShown}
          isLoading={this.props.isLoading}
          selectModal={this.props.selectModal}
          setSong ={this.props.setSong}
          searchText={this.props.searchText}
          type={this.props.type}
          setType={this.props.setType}
          sounds={this.props.sounds}
          soundOwners={this.props.soundOwners}
          songs={this.props.songs}
          catalog={this.props.catalog}
          selectedSongs={this.props.selectedSongs}
          onChangeSearchText={this.props.onChangeSearchText}
          goRetro={this.props.goRetro}
        />
      </div>
    );
  }
}

export default PlatformBody;
