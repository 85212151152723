import './RetroUI/RetroPlatformMusicSectionRow.css';
import './SoundSong.css';
import React, { Component } from 'react';
import { IoMdHeartEmpty, IoMdHeart, IoIosCheckmarkCircle } from 'react-icons/io';
import * as encryptUtils from './encryptDecryptUtils';
import * as loggingUtils from './utils/loggingUtils';

class CatalogSong extends Component {
  constructor(props) {
    super(props);
    this.visitOwner = this.visitOwner.bind(this);
    this.onClickHeart = this.onClickHeart.bind(this);
    this.state = {
      isHeartClicked: this.props.media.userVote,
    }
  }

  visitOwner(e) {
    e.stopPropagation();
    window.open(
      'https://opensea.io/' + this.props.media.owner, "_blank"
    );
  }

  componentDidUpdate(prevProps) {
    if(prevProps.media.userVote !== this.props.media.userVote) {
      this.setState({
        isHeartClicked: this.props.media.userVote,
      });
    }
  }

  async onClickHeart() {
    if(this.props.address == null) {
      this.props.onUserClickedConnectWallet();
    } else {
      if(!this.props.media.userVote) {
        await encryptUtils.logUpvote(this.props.media.tokenCID, this.props.address);
        loggingUtils.logEvent(
          this.props.address,
          'music_player',
          'music_player_upvote_song',
          'info',
          "https://the402.xyz",
          {
            song: this.props.media,
          },
        );
        this.props.media.userVote = true;
        this.props.media.totalUpvotes += 1;
        this.setState({
          isHeartClicked: true,
        });
      } else {
        await encryptUtils.logRemoveUpvote(this.props.media.tokenCID, this.props.address);
        loggingUtils.logEvent(
          this.props.address,
          'music_player',
          'music_player_remove_upvote_song',
          'info',
          "https://the402.xyz",
          {
            song: this.props.media,
          },
        );
        this.props.media.userVote = false;
        this.props.media.totalUpvotes -= 1;
        this.setState({
          isHeartClicked: false,
        });
      }
    }
  }

  render() {
    let songName = this.props.media.name;
    let artistName = this.props.media.artist;
    return (
        <div className="flex items-center">
          <div className="mt-2 w-full dark:hover:bg-zinc-700 hover:bg-gray-200 hover:text-gray-800 dark:hover:text-white text-gray-600 dark:text-gray-400 p-2 rounded-md flex justify-center" onClick={
            () => {
              if(this.props.address == null) {
                this.props.onUserClickedConnectWallet();
              } else {
                this.props.setSong(this.props.media, this.props.index);
              }
            }
          }>
              <div className="flex items-center w-1/12 font-medium text-sm">
                {this.props.index + 1}
              </div>
              <div className="flex w-6/12 sm:w-4/12 items-center cursor-pointer text-sm">
                <div className="w-4/12 sm:w-auto flex sm:items-center flex-col justify-center">
                  {
                    this.props.media.image != null
                      ?
                    <img className="platformMusicAlbumArt" src={this.props.media.image} alt="cover"/>
                      :
                    <div className="platformMusicAlbumArt"></div>
                  }
                </div>
                <div className = "w-8/12 sm:w-auto flex ml-4 items-center cursor-pointer text-sm">
                  {songName}
                </div>
              </div>
              <div className = "flex w-4/12 sm:w-3/12 items-center cursor-pointer text-sm">
                {artistName}
              </div>
              <div className = "invisible sm:visible flex w-0 sm:w-3/12 items-center cursor-pointer text-sm" onClick={this.visitOwner}>
                {
                  this.props.media.owner.includes("0x")
                    ?
                  this.props.media.owner.substring(0,5) + "..." + this.props.media.owner.substring(this.props.media.owner.length - 5)
                    :
                  this.props.media.owner
                }
              </div>
              <div className = "flex w-1/12 justify-end items-center cursor-pointer text-sm">
                {
                  this.state.isHeartClicked
                    ?
                  <IoMdHeart
                    className="text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await this.onClickHeart();
                    }}
                    color="red"
                    size="14px"
                  />
                    :
                  <IoMdHeartEmpty
                    className="text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await this.onClickHeart();
                    }}
                    size="14px"
                  />
                }
              </div>
          </div>
        </div>
    );
  }
}

export default CatalogSong;
