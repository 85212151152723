import React, { Component } from 'react';
import PlatformSidebar from './PlatformSidebar.js';
import PlatformMusicPlayer from './PlatformMusicPlayer.js';
import PlatformBody from './PlatformBody.js';
import RetroPlatform from './RetroUI/RetroPlatform.js';
import * as encryptUtils from './encryptDecryptUtils';
import * as loggingUtils from './utils/loggingUtils';
import * as loadExistingNFTs from './utils/loadExistingNFTs';
import axios from 'axios';
import './Platform.css';

class Platform extends Component {
  constructor(props) {
    super(props);
    this.selectModal = this.selectModal.bind(this);
    this.setSong = this.setSong.bind(this);
    this.getNextSong = this.getNextSong.bind(this);
    this.getSongBefore = this.getSongBefore.bind(this);
    this.goRetro = this.goRetro.bind(this);
    this.decryptSong = this.decryptSong.bind(this);
    this.changeSearchText = this.changeSearchText.bind(this);
    this.revokeAccess = this.revokeAccess.bind(this);
    this.onUserClickedConnectWallet = this.onUserClickedConnectWallet.bind(this);
    this.setType = this.setType.bind(this);
    this.setFirstSong = this.setFirstSong.bind(this);

    this.state = {
      modalShown: '',
      currentSong: {
        tokenId: null,
        tokenCID: null,
        image: null,
        name: "",
        artist: "",
        url: null,
        type: "",
        owner: null,
        totalUpvotes: 0,
        userVote: false,
        platform: null,
      },
      ind: -1,
      searchText: '',
      filteredSongs: [],
      selectedSongs: [],
      allSongs: [],
      retro: this.props.initiallyRetro,
      isForcingPlay: false,
      type: 0,
      catalog: [],
      songs: [],
      sounds: [],
      soundOwners: {},
      glass: [],
      glassOwners: {},
    }
  }

  onUserClickedConnectWallet() {
    loggingUtils.logEvent(
      'wallet_unconnected_user',
      'music_player',
      'music_player_clicked_song_with_wallet_disconnected',
      'info',
      "https://the402.xyz",
      {},
    );
    let buttons = document.getElementsByTagName("button");
    for(let i = 0; i < buttons.length; i++) {
      let button = buttons[i];
      if(button.outerText === "Connect Wallet") {
        button.click();
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if(this.props.account !== prevProps.account) {
      let address = null;
      if(this.props.account != null) {
        address = this.props.account.address;
      }
      this.setState({
        address,
      });
      let mintedSongs = [];
      let soundInfo = await loadExistingNFTs.getSoundNFTs(address);
      let sounds = soundInfo["sounds"];
      let soundOwners = soundInfo["soundOwners"];
      let catalog = await loadExistingNFTs.getCatalogNFTs(address);
      let glassInfo = await loadExistingNFTs.getGlassNFTs(address);
      let glass = glassInfo["glass"];
      let glassOwners = glassInfo["glassOwners"];
      let allSongs = catalog.concat(mintedSongs).concat(sounds).concat(glass);

      this.setState({
        mintedSongs,
        catalog,
        glass,
        glassOwners,
        sounds,
        soundOwners,
        filteredSongs: allSongs,
        selectedSongs: allSongs,
        allSongs,
      });

      if(window.location.href !== window.location.origin) {
        let splitURL = window.location.href.split("/");
        let songDetails = splitURL[splitURL.length - 1];
        let splitSongDetails = songDetails.split("_");
        let artist = splitSongDetails[0];
        let songName = splitSongDetails[1];
        for(let i = 0; i < allSongs.length; i++) {
          if(encodeURIComponent(allSongs[i].artist.trim()) === artist && encodeURIComponent(allSongs[i].name.trim()) === songName) {
            this.setSong(allSongs[i], i);
          }
        }
      }
    }
  }

  async componentDidMount() {
    let address = null;
    if(this.props.account != null) {
      address = this.props.account.address;
    }
    this.setState({
      address,
    });

    // When committing: 2nd line below should be commented out and 1st uncommented, when testing flip
    let mintedSongs = [];
    mintedSongs = [];
    mintedSongs = [];
    let soundInfo = await loadExistingNFTs.getSoundNFTs(address);
    let sounds = soundInfo["sounds"];
    let soundOwners = soundInfo["soundOwners"];
    let catalog = await loadExistingNFTs.getCatalogNFTs(address);
    let glassInfo = await loadExistingNFTs.getGlassNFTs(address);
    let glass = glassInfo["glass"];
    let glassOwners = glassInfo["glassOwners"];
    let allSongs = catalog.concat(mintedSongs).concat(sounds).concat(glass);

    this.setState({
      catalog,
      glass,
      glassOwners,
      songs: mintedSongs,
      sounds,
      soundOwners,
      filteredSongs: allSongs,
      selectedSongs: allSongs,
      allSongs,
    });

    if(window.location.href !== window.location.origin) {
      let splitURL = window.location.href.split("/");
      let songDetails = splitURL[splitURL.length - 1];
      let splitSongDetails = songDetails.split("_");
      let artist = splitSongDetails[0];
      let songName = splitSongDetails[1];
      for(let i = 0; i < allSongs.length; i++) {
        if(encodeURIComponent(allSongs[i].artist.trim()) === artist && encodeURIComponent(allSongs[i].name.trim()) === songName) {
          this.setSong(allSongs[i], i);
        }
      }
    }
  }

  setType(type){
    let selectedSongs;
    if(type === 0) {
      selectedSongs = this.state.allSongs;
    } else if(type === 1) {
      selectedSongs = this.state.catalog;
    } else if(type === 2) {
      selectedSongs = this.state.songs;
    } else if(type === 3) {
      selectedSongs = this.state.sounds;
    } else {
      selectedSongs = this.state.glass;
    }
    this.setState({
      filteredSongs: selectedSongs,
      searchText: '',
      selectedSongs,
      type,
    });
    if(this.state.address != null) {
      loggingUtils.logEvent(
        this.state.address,
        'music_player',
        'music_player_set_type',
        'info',
        "https://the402.xyz",
        {
          type: type,
        },
      );
    }
  }

  changeSearchText(event) {
    this.setState({
      searchText: event.target.value,
      filteredSongs: this.state.selectedSongs.filter(
        song => {
          let doesNameMatch = song.name != null && song.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(event.target.value.toLowerCase());
          let doesArtistMatch = song.artist != null && song.artist.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(event.target.value.toLowerCase());
          return doesNameMatch || doesArtistMatch;
        }
      ),
    });
  }

  selectModal(modal) {
    this.setState({
      modalShown: modal
    });
    if(this.state.address != null) {
      loggingUtils.logEvent(
        this.state.address,
        'music_player',
        'music_player_select_modal',
        'info',
        "https://the402.xyz",
        {
          modal: modal,
        },
      );
    }
  }

  getNextSong() {
    let currId = this.state.ind;
    let currIdMod = (currId + 1) % this.state.filteredSongs.length;
    this.setSong(this.state.filteredSongs[currIdMod], currIdMod);
    if(this.state.address != null) {
      loggingUtils.logEvent(
        this.state.address,
        'music_player',
        'music_player_next_song',
        'info',
        "https://the402.xyz",
        {},
      );
    }
  }

  getSongBefore() {
    let currId = this.state.ind;
    let currIdMod = (currId + this.state.filteredSongs.length - 1) % this.state.filteredSongs.length;
    this.setSong(this.state.filteredSongs[currIdMod], currIdMod);
    if(this.state.address != null) {
      loggingUtils.logEvent(
        this.state.address,
        'music_player',
        'music_player_previous_song',
        'info',
        "https://the402.xyz",
        {},
      );
    }
  }

  async setFirstSong() {
    if(this.state.filteredSongs.length > 0) {
      await this.setSong(this.state.filteredSongs[0], 0);
    }
  }

  async setSong(song, ind) {
    this.setState({currentSong: song, ind: ind});
    if(song.tokenId != null) {
      await this.decryptSong(song);
    }
    if(this.state.address != null) {
      await loggingUtils.logEvent(
        this.state.address,
        'music_player',
        'music_player_set_song',
        'info',
        "https://the402.xyz",
        {
          song: song,
        },
      );
    }
  }

  async decryptSong(song) {
    if(song.url != null) {
      return;
    }
    let authSig;
    let balance;
    if(this.state.address == null) {
      balance = 0;
    } else {
       balance = await encryptUtils.getBalance(this.state.address, song["tokenId"]);
    }
    let tokenCreator = song["artist"];
    let hasPriorAccess = (
      parseInt(balance) > 0 ||
      tokenCreator.toString() === this.state.address
    );
    if(!hasPriorAccess) {
      await encryptUtils.approveAccessMM(this.state.address, song["tokenId"], parseInt(song["impressionFee"]));
    }
    authSig = await encryptUtils.getAuthSig();

    let ppvMetadata = await axios.get(song["uri"]);
    let symmetricKeyRaw = ppvMetadata.data.encryptedSymmetricKey;
    let encryptedSymmetricKey = Object.keys(symmetricKeyRaw).map(function(key){
      return symmetricKeyRaw[key];
    });
    encryptedSymmetricKey = Uint8Array.from(encryptedSymmetricKey);
    let encryptedBlob = await fetch(ppvMetadata.data.encryptedURL).then(res => res.blob());
    let decryptedBlob = await encryptUtils.decryptSong(encryptedSymmetricKey, encryptedBlob, song["authUUID"], authSig);
    let url =  URL.createObjectURL(new Blob([decryptedBlob]));
    song["url"] = url;
    this.setState({
      currentSong: song,
    });
    if(this.state.address != null) {
      await loggingUtils.logEvent(
        this.state.address,
        'music_player',
        'music_player_decrypt_song_finish',
        'info',
        "https://the402.xyz",
        {
          song: song,
        },
      );
    }
  }

  async revokeAccess() {
    let song = this.state.currentSong;
    if(song.type !== "free" && this.state.address != null) {
      await encryptUtils.revokeAccess(this.state.address, song["tokenId"]);
    }
    this.getNextSong();
    if(this.state.address != null) {
      await loggingUtils.logEvent(
        this.state.address,
        'music_player',
        'music_player_revoke_access',
        'info',
        "https://the402.xyz",
        {
          song: song,
        },
      );
    }
  }

  goRetro() {
    this.setState({retro: true});
    if(this.state.address != null) {
      loggingUtils.logEvent(
        this.state.address,
        'music_player',
        'music_player_go_retro',
        'info',
        "https://the402.xyz",
        {},
      );
    }
  }

  render() {
    return (
      <div>
        {
          !this.state.retro
            ?
          <div className = "dark:bg-custom-black bg-white shadow-sm min-h-screen">
            {
              this.state.currentSong.url != null
                ?
              <PlatformMusicPlayer
                address={this.state.address}
                onUserClickedConnectWallet={this.onUserClickedConnectWallet}
                song={this.state.currentSong}
                nextSong={this.getNextSong}
                backSong={this.getSongBefore}
                privateKey={this.state.privateKey}
                litDecrypt={this.decryptSong}
                revokeAccess={this.revokeAccess}
              />
                :
              null
            }
            <PlatformSidebar
              modalShown={this.state.modalShown}
              selectModal={this.selectModal}
            />
            <PlatformBody
              modalShown={this.state.modalShown}
              address={this.state.address}
              onUserClickedConnectWallet={this.onUserClickedConnectWallet}
              selectModal={this.selectModal}
              setSong = {this.setSong}
              goRetro={this.goRetro}
              isLoading={this.state.allSongs.length === 0}
              searchText={this.state.searchText}
              type={this.state.type}
              setType={this.setType}
              sounds={this.state.sounds}
              soundOwners={this.state.soundOwners}
              currentSong={this.state.currentSong}
              songs={this.state.songs}
              catalog={this.state.catalog}
              selectedSongs={this.state.filteredSongs}
              onChangeSearchText={this.changeSearchText}
            />
          </div>
            :
          <RetroPlatform
            address={this.state.address}
            onUserClickedConnectWallet={this.onUserClickedConnectWallet}
            song={this.state.currentSong}
            nextSong={this.getNextSong}
            backSong={this.getSongBefore}
            isLoading={this.state.allSongs.length === 0}
            litDecrypt={this.decryptSong}
            revokeAccess={this.revokeAccess}
            modalShown={this.state.modalShown}
            selectModal={this.selectModal}
            setSong={this.setSong}
            setFirstSong={this.setFirstSong}
            searchText={this.state.searchText}
            type={this.state.type}
            setType={this.setType}
            sounds={this.state.sounds}
            soundOwners={this.state.soundOwners}
            glass={this.state.glass}
            glassOwners={this.state.glassOwners}
            songs={this.state.songs}
            catalog={this.state.catalog}
            selectedSongs={this.state.filteredSongs}
            onChangeSearchText={this.changeSearchText}
          />
        }
      </div>
    );
  }
}

export default Platform;
