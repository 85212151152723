import './CreatePasswordModal.css';
import { IoMdClose } from 'react-icons/io';

function CreatePasswordModal(props) {

  const onSubmit = () => {
    let password = document.getElementById("passwordInput").value;
    props.onSubmit(password);
    props.selectModal("");
  };

  return (
    <div className = "modal-Background">
    <div className="createPasswordModal">
      <div className = "createPasswordContainer">
        <div className = "createPasswordHeaderContainer">
          <IoMdClose onClick={() => props.selectModal("")}
          className="createPasswordModalXButton"
          size={24}
          />
        </div>
        <div className = "createPasswordHeader">
          Create password
        </div>
        <div className = "createPasswordHeaderSubText">
          You will use this to unlock your wallet
        </div>
        <div className = "passwordFormMainContainer">
          <form className = "passwordFormContainer">
            <input id = "passwordInput" className = "passwordInput" type="password" name="password" placeholder = "Enter password" />
            <input className = "passwordInput" type="password" name="confirm password" placeholder = "Confirm password" />
          </form>
        </div>
        <div className = "passwordSubmitButtonContainer">
          <div className = "passwordFormSubmitButton" onClick={onSubmit}>
            <input className = "createPasswordSubmit" type="submit" value="Submit" />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default CreatePasswordModal;
