import './RetroPlatformSidebarMenuItems.css';

function RetroPlatformSidebarMenuItems(props) {
  return (
    <div className="retroPlatformSidebarMenuItemsContainer">
      <div
        onClick={() => props.setType(0)}
        className={
          props.type === 0
            ?
          "retroPlatformSidebarMenuItemSelected"
            :
          "retroPlatformSidebarMenuItemUnSelected"
        }
      >
        <div className = "retroPlatformSidebarMenuText">
          All
        </div>
      </div>
      <div
        onClick={() => props.setType(3)}
        className={
          props.type === 3
            ?
          "retroPlatformSidebarMenuItemSelected"
            :
          "retroPlatformSidebarMenuItemUnSelected"
        }
      >
        <div className = "retroPlatformSidebarMenuText">
          Sound
        </div>
      </div>
      <div
        onClick={() => props.setType(1)}
        className={
          props.type === 1
            ?
          "retroPlatformSidebarMenuItemSelected"
            :
          "retroPlatformSidebarMenuItemUnSelected"
        }
      >
        <div className = "retroPlatformSidebarMenuText">
          Catalog
        </div>
      </div>
    </div>
  );
}

export default RetroPlatformSidebarMenuItems;
