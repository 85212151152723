import './RetroPlatformSidebar.css';
import RetroPlatformSidebarMenuItems  from './RetroPlatformSidebarMenuItems';
import React, { Component } from 'react';
import RetroPlatformMusicCoverPhoto from './RetroPlatformMusicCoverPhoto';

class RetroPlatformSidebar extends Component {
  render() {
    return (
      <div className = "retroPlatformSideBar">
        <div className = "retroPlatformSidebarOuterContainer">
          <div className = "retroPlatformSidebarContainer">
            <div className = "retroLibrary">
              LIBRARY
            </div>
              <RetroPlatformSidebarMenuItems type={this.props.type} setType={this.props.setType} />
          </div>
          <div className ="musicCoverPhoto-Container">
            <RetroPlatformMusicCoverPhoto song={this.props.song} />
          </div>
        </div>
      </div>
    );
  }
}

export default RetroPlatformSidebar;
