import React, { Component } from 'react';
import RetroPlatformMusicSection from './RetroPlatformMusicSection.js'
import './RetroPlatformBody.css';

class RetroPlatformBody extends Component {
  render() {
    return (
      <div className = "retroPlatformBodyContainer">
        <RetroPlatformMusicSection
          address={this.props.address}
          isLoading={this.props.isLoading}
          modalShown={this.props.modalShown}
          onUserClickedConnectWallet={this.props.onUserClickedConnectWallet}
          selectModal={this.props.selectModal}
          selectedSongs={this.props.selectedSongs}
          setSong={this.props.setSong}
          playingSong={this.props.playingSong}
          soundOwners={this.props.soundOwners}
          glassOwners={this.props.glassOwners}
        />
      </div>
    );
  }
}

export default RetroPlatformBody;
