import React, { Component } from 'react';
import Wallet from './Wallet.js';
import WelcomeModal from './WelcomeModal.js';
import SeedPhraseModal from './SeedPhraseModal';
import CreatePasswordModal from './CreatePasswordModal';
import ImportSeedModal from './ImportSeedModal';

const ONBOARDING_STEPS = {
    WELCOME: 0,
    NEW_SEED: 1,
    CREATE_PASSWORD: 2,
    IMPORT_SEED: 3,
};

class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.continue = this.continue.bind(this);
    this.state = {
      step: 0,
    }
  }

  continue(toStep) {
    this.setState({
      step: toStep,
    });
  }

  generateKey() {
    return Wallet.generateWallet();
  }

  importKey(key) {
    Wallet.importWalletFromKey(key);
  }

  encryptWallet(password) {
    Wallet.saveWallet(password);
  }

  render() {
    switch(this.state.step) {
      case 0:
        return <WelcomeModal onboardingSteps={ONBOARDING_STEPS} updateStep={this.continue} selectModal={this.props.selectModal} />;
      case 1:
        return <SeedPhraseModal onboardingSteps={ONBOARDING_STEPS} updateStep={this.continue} seed={this.generateKey()} selectModal={this.props.selectModal}/>;
      case 2:
        return <CreatePasswordModal onboardingSteps={ONBOARDING_STEPS} updateStep={this.continue} onSubmit={this.encryptWallet} selectModal={this.props.selectModal}/>;
      case 3:
        return <ImportSeedModal onboardingSteps={ONBOARDING_STEPS} updateStep={this.continue} onSubmit={this.importKey} selectModal={this.props.selectModal}/>;
    }
  }
}

export default Onboarding;
