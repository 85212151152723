import './PlatformMusicSectionRow.css';
import React, { Component } from 'react';
import { IoMdHeartEmpty, IoMdHeart, IoIosCheckmarkCircle } from 'react-icons/io';
import * as encryptUtils from './encryptDecryptUtils';
import * as loggingUtils from './utils/loggingUtils';

class PlatformMusicSectionRow extends Component {
  constructor(props) {
    super(props);
    this.handleClickSong = this.handleClickSong.bind(this);
    this.onClickHeart = this.onClickHeart.bind(this);
    this.state = {
      isHeartClicked: this.props.song.userVote,
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.song.userVote !== this.props.song.userVote) {
      this.setState({
        isHeartClicked: this.props.song.userVote,
      });
    }
  }

  handleClickSong() {
    loggingUtils.logEvent(
      this.props.address != null ? this.props.address : 'wallet_unconnected_user',
      'music_player',
      'music_player_click_song',
      'info',
      "https://the402.xyz",
      {
        song: this.props.song,
      },
    );
    let url = 'http://localhost:3000/media/' + this.props.song.tokenId;
    window.location.href = url;
  }

  async onClickHeart() {
    if(this.props.address == null) {
      loggingUtils.logEvent(
        'wallet_unconnected_user',
        'music_player',
        'music_player_click_upvote_with_wallet_disconnected',
        'info',
        "https://the402.xyz",
        {
          song: this.props.song,
        },
      );
      this.props.onUserClickedConnectWallet();
    } else {
      if(!this.props.song.userVote) {
        await encryptUtils.logUpvote(this.props.song.tokenCID, this.props.address);
        loggingUtils.logEvent(
          this.props.address,
          'music_player',
          'music_player_upvote_song',
          'info',
          "https://the402.xyz",
          {
            song: this.props.song,
          },
        );
        this.props.song.userVote = true;
        this.props.song.totalUpvotes += 1;
        this.setState({
          isHeartClicked: true,
        });
      } else {
        await encryptUtils.logRemoveUpvote(this.props.song.tokenCID, this.props.address);
        loggingUtils.logEvent(
          this.props.address,
          'music_player',
          'music_player_remove_upvote_song',
          'info',
          "https://the402.xyz",
          {
            song: this.props.song,
          },
        );
        this.props.song.userVote = false;
        this.props.song.totalUpvotes -= 1;
        this.setState({
          isHeartClicked: false,
        });
      }
    }
  }

  render(){
    return (
      <div className="mt-2 w-full dark:hover:bg-zinc-700 hover:bg-gray-200 hover:text-gray-800 dark:hover:text-white  text-gray-600 dark:text-gray-400 p-2 rounded-md flex justify-center" onClick={
        () => {
          if(this.props.address == null) {
            this.props.onUserClickedConnectWallet();
          } else {
            this.props.setSong(this.props.song, this.props.index);
          }
        }
      }>
        <div className = "flex items-center w-1/12 font-medium text-sm">
          {this.props.index + 1}
        </div>
        <div className="flex w-6/12 sm:w-4/12 items-center cursor-pointer text-sm">
        <div className="w-4/12 sm:w-auto flex sm:items-center flex-col justify-center">
          <img className="platformMusicAlbumArt" src={this.props.song.image} alt="cover"/>
        </div>
        <div
          className = "w-8/12 text-ellipsis overflow-hidden sm:w-auto flex ml-4 items-center cursor-pointer text-sm"
          onClick={(e) => {
            this.handleClickSong();
          }}
        >
          {this.props.song.name}
        </div>
        </div>
        <div className = "flex w-4/12 sm:w-3/12 items-center cursor-pointer text-sm">

        </div>
        <div className = "invisible sm:visible flex w-0 sm:w-3/12 items-center cursor-pointer text-sm">
          {this.props.song.artist.substring(0,5) + "..." + this.props.song.artist.substring(this.props.song.artist.length - 5)}
        </div>
        <div className = "flex w-1/12 justify-end items-center cursor-pointer text-sm">
          {
            this.state.isHeartClicked
              ?
            <IoMdHeart
              className="text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
              onClick={async (e) => {
                e.stopPropagation();
                await this.onClickHeart();
              }}
              color="red"
              size="14px"
            />
              :
            <IoMdHeartEmpty
              className="text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
              onClick={async (e) => {
                e.stopPropagation();
                await this.onClickHeart();
              }}
              size="14px"
            />
          }
        </div>
      </div>
    );
  }
}

export default PlatformMusicSectionRow;
