import './RetroPlatformMusicSectionRow.css';
import React, { Component } from 'react';
import { IoMdHeartEmpty, IoMdHeart, IoMdShare, IoIosShareAlt, IoIosCheckmarkCircle } from 'react-icons/io';
import * as encryptUtils from '../encryptDecryptUtils';
import * as loggingUtils from '../utils/loggingUtils';

class RetroPlatformMusicSectionRow extends Component {
  constructor(props) {
    super(props);

    this.onClickHeart = this.onClickHeart.bind(this);
    this.onClickShareLink = this.onClickShareLink.bind(this);
    this.setCopyMessage = this.setCopyMessage.bind(this);

    this.state = {
      copyMessage: "",
      isCopied: false,
      isHeartClicked: this.props.song.userVote,
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onClickShareLink(e) {
    e.stopPropagation();
    let artistSanitized = encodeURIComponent(this.props.song.artist.trim());
    let songNameSanitized = encodeURIComponent(this.props.song.name.trim());
    let shareLink = window.location.origin + '/' + artistSanitized + "_" + songNameSanitized;
    let address = this.props.address != null ? this.props.address : "wallet_unconnected_user";
    navigator.clipboard.writeText(shareLink);
    loggingUtils.logEvent(
      address,
      'music_player',
      'music_player_share_song',
      'info',
      "https://the402.xyz",
      {
        song: this.props.song,
      },
    );
    this.setState({
      isCopied: true,
    });
    this.setCopyMessage("Copied");
    this.timer = setTimeout(() => {
      this.setCopyMessage("");
      this.setState({isCopied: false});
    }, 2000);
  }

  setCopyMessage(message) {
    this.setState({
      copyMessage: message,
    });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.song.userVote !== this.props.song.userVote) {
      this.setState({
        isHeartClicked: this.props.song.userVote,
      });
    }
  }

  async onClickHeart() {
    if(this.props.address == null) {
      this.props.onUserClickedConnectWallet();
    } else {
      if(!this.props.song.userVote) {
        await encryptUtils.logUpvote(this.props.song.tokenCID, this.props.address);
        loggingUtils.logEvent(
          this.props.address,
          'music_player',
          'music_player_upvote_song',
          'info',
          "https://the402.xyz",
          {
            song: this.props.song,
          },
        );
        this.props.song.userVote = true;
        this.props.song.totalUpvotes += 1;
        this.setState({
          isHeartClicked: true,
        });
      } else {
        await encryptUtils.logRemoveUpvote(this.props.song.tokenCID, this.props.address);
        loggingUtils.logEvent(
          this.props.address,
          'music_player',
          'music_player_remove_upvote_song',
          'info',
          "https://the402.xyz",
          {
            song: this.props.song,
          },
        );
        this.props.song.userVote = false;
        this.props.song.totalUpvotes -= 1;
        this.setState({
          isHeartClicked: false,
        });
      }
    }
  }

  render() {
    let mainContainerClassName;
    if(this.props.song.name === this.props.playingSong.name && this.props.song.artist === this.props.playingSong.artist) {
      mainContainerClassName = "retroPlatformMusicRowMainContainerSelected";
    } else if(this.props.index % 2 === 1) {
      mainContainerClassName = "retroPlatformMusicRowMainContainer";
    } else {
      mainContainerClassName = "retroPlatformMusicRowMainContainerAlternate";
    }
    // let ownerText = "";
    // if(this.props.song.owner != null) {
    //   ownerText = this.props.song.owner;
    //   if(ownerText.length > 30) {
    //     ownerText = ownerText.substring(0, 4) + "..." + ownerText.substring(ownerText.length - 4);
    //   }
    // } else if(this.props.song.platform === "Sound") {
    //   if(this.props.song.name in this.props.soundOwners) {
    //     let owners = this.props.soundOwners[this.props.song.name];
    //     if(owners != null && owners.length > 0) {
    //       let firstOwner = owners[0];
    //       if(firstOwner.length > 30) {
    //         firstOwner = firstOwner.substring(0, 4) + "..." + firstOwner.substring(firstOwner.length - 4);
    //       }
    //       ownerText = firstOwner + " + " + owners.length + " others";
    //     }
    //   }
    // } else if(this.props.song.platform === "Glass") {
    //   if(this.props.song.name in this.props.glassOwners) {
    //     let owners = this.props.glassOwners[this.props.song.name];
    //     if(owners != null && owners.length > 0) {
    //       let firstOwner = owners[0];
    //       if(firstOwner.length > 30) {
    //         firstOwner = firstOwner.substring(0, 4) + "..." + firstOwner.substring(firstOwner.length - 4);
    //       }
    //       ownerText = firstOwner + " + " + owners.length + " others";
    //     }
    //   }
    // }
    return (
      <div
        className="retroPlatformMusicRowContainer"
        onClick={
          () => {
            if(this.props.address == null) {
              this.props.onUserClickedConnectWallet();
            } else {
              this.props.setSong(this.props.song, this.props.index);
            }
          }
        }
      >
        <div className = {mainContainerClassName}>
          <div className = "retroPlatformMusicRowTitleAritst-Container">
            <div className = "retroPlatformMusicRowTitle">
              {this.props.song.name}
            </div>
            <div className = "retroPlatformMusicRowArtists">
              {this.props.song.artist}
            </div>
          </div>
          <div className = "retroPlatformMusicRowUpvotes">
            <div className = "retroPlatformMusicHearts">
              {
                this.state.isHeartClicked
                  ?
                <IoMdHeart
                  onClick={async (e) => {
                    e.stopPropagation();
                    await this.onClickHeart();
                  }}
                  color="red"
                  size="14px"
                />
                  :
                <IoMdHeartEmpty
                  onClick={async (e) => {
                    e.stopPropagation();
                    await this.onClickHeart();
                  }}
                  size="14px"

                />
              }
            </div>
            <div className = "retroPlatformMusicHeartsTotal">
              {this.props.song.totalUpvotes}
            </div>
          </div>
          <div className = "retroPlatformMusicRowShares">
          {
            this.state.isCopied
              ?
            <IoIosCheckmarkCircle color="green" />
              :
            <IoIosShareAlt
              onClick={this.onClickShareLink}
              onMouseEnter={() => this.setCopyMessage("Share")}
              onMouseLeave={() => this.setCopyMessage("")}
            />
          }
          <div className="retroPlatformMusicSectionCopyMessage">
            {this.state.copyMessage}
          </div>
          {
            /*
              <div className="retroPlatformMusicOwner">
                {ownerText}
              </div>
            */
          }
          </div>
        </div>
      </div>
    );
}
}

export default RetroPlatformMusicSectionRow;
