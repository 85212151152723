import QRCode from 'qrcode';
import React, { Component } from 'react';
import {useEffect, useState} from 'react';
import './QRCodeComponent.css';

class QRCodeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: '',
    }
  }

  componentDidMount() {
    let self = this;
    QRCode.toDataURL(this.props.publicKey).then((data) => {
      self.setState({
        src: data,
      });
    });
  }

  render() {
    return (
      <div>
        <img className = "QRCode" src = {this.state.src}/>
      </div>
   );
  }
}

export default QRCodeComponent;
