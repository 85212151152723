import './RetroPlatformCover.css';
import React, { Component } from 'react';
import { FaPause, FaPlay, FaForward, FaBackward } from 'react-icons/fa';
import * as encryptUtils from '../encryptDecryptUtils';
import * as loggingUtils from '../utils/loggingUtils';

class RetroPlatformCover extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.onPlaying = this.onPlaying.bind(this);
    this.onEnded = this.onEnded.bind(this);

    this.audioPlayer = React.createRef();

    this.state = {
      seekValue: 0,
      totalListenTime: 0,
      currentTime: "00.00",
      remainingTime: "00.00",
      playing: true
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.song !== this.props.song){
      this.setState({
        song: this.props.song,
      });
    }
  }

  async play() {
    if(this.props.address == null) {
      this.props.onUserClickedConnectWallet();
    } else if(this.props.song.name === "") {
      await this.props.setFirstSong();
    } else {
      if(!this.audioPlayer.current.paused){
        this.audioPlayer.current.pause();
      } else{
        this.audioPlayer.current.play();
      }

      let playState = !this.audioPlayer.current.paused;
      this.setState({playing: playState});
    }
  }

  onPlaying() {
    if(this.props.song.url !== "") {
      let totalListenTime = 0;
      for(let i = 0; i < this.audioPlayer.current.played.length; i++) {
        totalListenTime += this.audioPlayer.current.played.end(i) - this.audioPlayer.current.played.start(i)
      }
      if(totalListenTime > 30 && this.state.totalListenTime < 30) {
        encryptUtils.logImpression(this.props.song.tokenCID, this.props.address);
        if(this.props.address != null) {
          loggingUtils.logEvent(
            this.props.address,
            'music_player',
            'music_player_log_impression',
            'info',
            "https://the402.xyz",
            {
              song: this.props.song,
            },
          );
        }
      }
      this.setState({totalListenTime});
      let currentSeconds = parseFloat(this.audioPlayer.current.currentTime).toFixed(2);
      let current = new Date(currentSeconds * 1000).toISOString().substr(14, 5);
      let remainingSeconds = parseFloat(this.audioPlayer.current.duration - currentSeconds).toFixed(2);
      let remaining = "";
      let seekValue = 0;
      if (isNaN(remainingSeconds)) {
        remaining = "00.00"
      } else {
        remaining = new Date(remainingSeconds * 1000).toISOString().substr(14, 5);
        seekValue =  (this.audioPlayer.current.currentTime / this.audioPlayer.current.duration) * 100;
      }
      this.setState({currentTime: current, remainingTime: remaining, seekValue});
    }
  }

  onEnded() {
    this.props.revokeAccess();
    this.setState({totalListenTime: 0});
  }

  render() {
    return (
      <div className="retroPlatformCoverContainer">
        <audio
          src={this.props.song.url}
          ref={this.audioPlayer}
          onTimeUpdate={this.onPlaying}
          autoPlay
          onEnded={this.onEnded}
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>
        <div className="retroPlatform-ControlsAndSongContainer">
          <div className = "retroPlatformSong-buttons">
            <div className = "retroPlatform-backButtonBox" onClick={() => this.props.backSong()}>
              <div className = "retroPlatform-backButtonBackground">
                <FaBackward className = "retroPlatform-backButton"/>
              </div>
            </div>
            <div className = "retroPlatform-playButtonBox" onClick={async () => await this.play()}>
              <div className = "retroPlatform-playButtonBackground">
                {
                  this.audioPlayer.current == null || this.audioPlayer.current.paused
                    ?
                  <FaPlay className ="retroPlatform-playButton" />
                    :
                  <FaPause className ="retroPlatform-playButton" />
                }
              </div>
            </div>
            <div className = "retroPlatform-forwardButtonBox" onClick={() => this.props.nextSong()}>
              <div className = "retroPlatform-forwardButtonBackground">
                <FaForward className = "retroPlatform-forwardButton"/>
              </div>
            </div>
          </div>
          <div className = "platformSongBoxTitleContainer">
          <div className = "platformCoverTitleContainer">
            <div className = "retroPlatformCoverTitle">
              tunes.wtf
            </div>
          </div>
          {
            this.props.address == null
              ?
            <div className="retroPlatformSongBoxContainer">
              <div className = "retroPlatformSong-information">
                <div className="retroPlatformSongNameEmpty">
                  welcome
                </div>
                <div className = "retroPlatformAlbumName">
                  connect your wallet to start listening
                </div>
              </div>
              {
                this.props.song.url !== ""
                  ?
                <div className = "retroPlatform-scrollbarInfo">
                  <div className="retroPlatformSong-currentTime">
                    {this.state.currentTime}
                  </div>
                  <input className="retroPlatform-scrollbar"
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    value={this.state.seekValue}
                    onChange={(e) => {
                      const seekto = this.audioPlayer.current.duration * (+e.target.value / 100);
                      this.audioPlayer.current.currentTime = seekto;
                      this.setState({ seekValue: (e.target.value) });
                    }}
                  />
                  <div className="retroPlatformSong-remainingTime">
                    {this.state.remainingTime}
                  </div>
                </div>
                  :
                null
              }
            </div>
                :
            <div className="retroPlatformSongBoxContainer">
              <div className = "retroPlatformSong-information">
                <div className="retroPlatformSongName">
                  {this.props.song.name}
                </div>
                <div className = "retroPlatformAlbumName">
                  {this.props.song.artist}
                </div>
              </div>
              {
                this.props.song.url !== ""
                  ?
                <div className = "retroPlatform-scrollbarInfo">
                  <div className="retroPlatformSong-currentTime">
                    {this.state.currentTime}
                  </div>
                  <input className="retroPlatform-scrollbar"
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    value={this.state.seekValue}
                    onChange={(e) => {
                      const seekto = this.audioPlayer.current.duration * (+e.target.value / 100);
                      this.audioPlayer.current.currentTime = seekto;
                      this.setState({ seekValue: (e.target.value) });
                    }}
                  />
                  <div className="retroPlatformSong-remainingTime">
                    {this.state.remainingTime}
                  </div>
                </div>
                  :
                null
              }
            </div>
          }
          </div>
        </div>
      </div>
    );
  }
}

export default RetroPlatformCover;
