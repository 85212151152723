const axios = require('axios');
const { EncryptStorage } = require('encrypt-storage');

const walletAPI = require('ethereumjs-wallet').default;

var Wallet = {
    // Create a new wallet
    generateWallet: function() {
        if(this.wallet == null) {
            this.wallet = walletAPI.generate();
        }
        return this.wallet.getPrivateKeyString();
    },

    // Import existing wallet into our platform
    importWalletFromKey: function(privateKey) {
        if(privateKey.substring(0, 2) === "0x") {
            privateKey = privateKey.substring(2);
        }
        let buffer = Buffer.from(privateKey, "hex");
        this.wallet = walletAPI.fromPrivateKey(buffer);
    },

    // Retrieve existing key from local storage to init wallet
    // Should probably check `hasEncryptedKey()` before this
    restoreWallet: function(password) {
        // Need to add 10 characters for minimum length of secret
        let encryptStorage = new EncryptStorage(password + 'xxxxxxxxxx', {
            storageType: 'sessionStorage'
        });
        let key = encryptStorage.getItem('encrypted_key');
        this.importWalletFromKey(key);
    },

    // Properties

    address: function() {
        if (this.wallet == null) {
            return null;
        }
        return this.wallet.getAddressString();
    },

    privateKey: function() {
        if (this.wallet == null) {
            return null;
        }
        return this.wallet.getPrivateKeyString();
    },

    getBalance: async function() {
        if(this.wallet == null) {
            return null;
        }
        let url = "https://api.covalenthq.com/v1/80001/address/" + this.wallet.getAddressString() + "/balances_v2/";
        let data = await axios.get(url, {
            auth: {
                username: 'ckey_40989d55109f42e393bec903b89',
                password: '',
            }
        });
        if(data == null || !("items" in data["data"]["data"])) {
            return 0;
        } else {
            let realData = data["data"]["data"]
            for(let i = 0; i < realData["items"].length; i++) {
                if(realData["items"][i]["contract_ticker_symbol"] === "MATIC") {
                    return realData["items"][i]["balance"] * Math.pow(10, -realData["items"][i]["contract_decimals"]);
                }
            }
            return 0;
        }
    },

    // Save wallet credentials to local storage, encrypted with `password`

    saveWallet: function(password) {
        if (this.wallet == null) {
            return;
        }
        // Need to add 10 characters for minimum length of secret
        let encryptStorage = new EncryptStorage(password + 'xxxxxxxxxx', {
            storageType: 'sessionStorage'
        });
        encryptStorage.setItem('encrypted_key', this.wallet.getPrivateKeyString());
    },
}

export default Wallet;