import './ImportSeedModal.css';
import Wallet from './Wallet';
import { IoMdClose } from 'react-icons/io';

function ImportSeedModal(props) {

  const onSubmit = () => {
      let privateKey = document.getElementById("seedInput").value;
      props.onSubmit(privateKey);
      props.selectModal("");
  };

  return (
    <div className = "modal-Background">
    <div className="importSeedModal">
      <div className = "importSeedContainer">
        <div className = "importSeedHeaderContainer">
          <IoMdClose onClick={() => props.selectModal("")}
          className="importSeedModalXButton"
          size={24}
          />
        </div>
        <div className = "importSeedHeader">
          Import wallet
        </div>
        <div className = "importSeedHeaderSubText">
          Login with an existing private key
        </div>
        <div className = "seedFormMainContainer">
          <form className = "seedFormContainer">
            <input id = "seedInput" className = "seedInput" type="password" name="password" placeholder = "Enter private key" />
          </form>
        </div>
        <div className = "seedFormSubmitButton" onClick={() => onSubmit()}>
          <input className = "importSeedSubmit" type="submit" value="Continue" />
        </div>
      </div>
    </div>
    </div>
  );
}

export default ImportSeedModal;
