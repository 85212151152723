import React, { Component } from 'react';
import RetroPlatformSidebar from './RetroPlatformSidebar.js';
import RetroPlatformBody from './RetroPlatformBody.js';
import RetroPlatformCover from './RetroPlatformCover';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import './RetroPlatform.css';
import {useEffect} from 'react';

class RetroPlatform extends Component {
  componentDidMount() {
    document.body.classList.add('retroSilverBackground');
  }

  render() {
    return (
      <div className = "retroMusicPlatformContainer">
        <div className="retroPlatformConnectWalletContainer">
          <ConnectButton
            showBalance={false}
            chainStatus="none"
          />
        </div>
        <div className="retroMusicHeaderMainContainer">
        <div className="spacer">
        </div>
        <div className = "retroMusicPlayerPlatformHeaderContainer">
          <RetroPlatformCover
            address={this.props.address}
            song={this.props.song}
            setFirstSong={this.props.setFirstSong}
            onUserClickedConnectWallet={this.props.onUserClickedConnectWallet}
            nextSong={this.props.nextSong}
            backSong={this.props.backSong}
            revokeAccess={this.props.revokeAccess}
          />
          <div className = "retroMusicPlatformSearchContainer">
            <input
              type="text"
              value={this.props.searchText}
              onChange={this.props.onChangeSearchText}
              className="retroMusicSectionSearch"
              placeholder = "Search"
            />
          </div>
        </div>
        </div>
        <div className = "retroMusicPlatformBodyContainer">
          <div className = "retroPlatformContainer">
            <RetroPlatformSidebar
              song={this.props.song}
              type={this.props.type}
              setType={this.props.setType}
            />
          </div>
          <RetroPlatformBody
            address={this.props.address}
            isLoading={this.props.isLoading}
            modalShown={this.props.modalShown}
            onUserClickedConnectWallet={this.props.onUserClickedConnectWallet}
            selectModal={this.props.selectModal}
            selectedSongs={this.props.selectedSongs}
            setSong={this.props.setSong}
            playingSong={this.props.song}
            soundOwners={this.props.soundOwners}
            glassOwners={this.props.glassOwners}
          />
        </div>
        <div className="createdbythe402">
          created by the402
        </div>
      </div>
    );
  }
}

export default RetroPlatform;
