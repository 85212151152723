import './ImportFromPasswordButton.css';

function ImportFromPasswordButton(props) {
  return (
    <div className = "importFromPasswordButtonContainer">
      <button className = "importFromPasswordButton" onClick={() => props.selectModal("importFromPassword")}>
        <div className = "importFromPasswordButtonText">
          Log in
        </div>
      </button>
    </div>
  );
}

export default ImportFromPasswordButton;
