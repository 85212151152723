import './SeedPhraseModal.css';
import { IoMdClose } from 'react-icons/io';

function SeedPhraseModal(props) {
  return (
    <div className = "modal-Background">
    <div className="seedPhraseModal">
      <div className = "seedPhraseContainer">
        <div className = "seedPhraseHeaderContainer">
          <IoMdClose onClick={() => props.selectModal("")}
          className="seedPhraseModalXButton"
          size={24}
          />
        </div>
        <div className = "seedPhraseHeader">
          Secret Key
        </div>
        <div className = "seedPhraseHeaderSubText">
          This key is the ONLY way to recover your wallet. Do NOT share with anyone!
        </div>
        <div className = "seedPhraseModalBottomSection">
        <div className = "seedPhraseBoxContainer">
        <div className = "seedPhraseSecretContainer">
          <div className = "seedPhraseSecretContainerText">
          {props.seed}
          </div>
        </div>
        </div>
        <div className = "seedPhraseCopyMainContainer">
        <div className = "seedPhraseCopyContainer" onClick={() => navigator.clipboard.writeText(props.seed)}>
          <div className = "seedPhraseCopyText">
            Copy
          </div>
        </div>
        </div>
        <div className = "seedPhrasebackedupSelctionContainer">
          <div className = "seedPhrasebackedupNewbackedupButton" onClick={() => props.updateStep(props.onboardingSteps.CREATE_PASSWORD)}>
            <div className = "seedPhrasebackedupText">
              I backed it up!
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default SeedPhraseModal;
