import './PlatformMusicSection.css';
import PlatformMusicSectionRow from './PlatformMusicSectionRow.js';
import Onboarding from './Onboarding.js';
import AddFunds from './AddFunds.js';
import ImportFromPassword from './ImportFromPassword';
import CatalogSong from './CatalogSong';
import SoundSong from './SoundSong';
import { IoMdHeartEmpty } from 'react-icons/io';
import React, { Component } from 'react';
import defaultCover from './defaultCover.png';


class PlatformMusicSection extends Component {
  render() {
    return (
      <div className = "flex flex-col justify-center items-center mt-16">
      <div className="flex justify-center items-center w-screen">
        {this.props.currentSong.url != null
            ?
          this.props.currentSong.image != null
            ?
          <div className = "dark:bg-black bg-gray-200 flex justify-center py-20 items-center">
            <img id="blurred" class="backgroundBlurredImagePlatform" src={this.props.currentSong.image} alt="Current song" />
          </div>
            :
          <div className = "dark:bg-black bg-gray-200 flex justify-center py-20 items-center">
            <img id="blurred" class="backgroundBlurredImagePlatform" src={defaultCover} alt="Current song" />
          </div>
            :
          null
        }
        <div>
          {
            this.props.currentSong.url != null
              ?
            <div className="title-box-wrapPlatform">
            <div className="mt-8 flex flex-col items-center justify-center">
              {
                this.props.currentSong.image != null
                  ?
                <img class="w-72 h-72 rounded-md" src={this.props.currentSong.image} alt="Current song" />
                  :
                <div className="platformMusicHeader-AlbumArtwork"> </div>
              }
              <div className="mt-4 w-auto text-zinc-600 dark:text-zinc-400 font-medium text-md">
                {this.props.currentSong.artist}
              </div>
              <div className="w-auto text-zinc-600 dark:text-gray-100 px-6 font-medium text-md">
                {this.props.currentSong.name}
              </div>
              <div className="absolute">
              </div>
            </div>
            </div>
              :
            null
          }
        </div>
      </div>
        <input
          type="text"
          value={this.props.searchText}
          onChange={this.props.onChangeSearchText}
          className="rounded-lg my-8 bg-gray-200 dark:text-gray-500 dark:bg-zinc-800 w-80 p-2 "
          placeholder = "Search"
        />
        <div className="flex w-10/12 justify-center">
          <div className="bg-zinc-800 mx-1 w-20 flex items-center justify-center rounded-lg cursor-pointer sm:bg-zinc-800 sm:mx-4 sm:w-28 sm:flex items-center sm:justify-center sm:rounded-lg sm:cursor-pointer">
            <div onClick={() => this.props.setType(0)}
              className = {
                this.props.type === 0
                  ?
                  "flex items-center justify-center text-white w-28 h-10 dark:bg-zinc-800 bg-zinc-500 hover:bg-zinc-500 dark:hover:bg-zinc-700 rounded-lg cursor-pointer"
                    :
                  "flex items-center justify-center text-gray-400 w-28 h-10 dark:bg-zinc-800 bg-zinc-500 hover:bg-zinc-500 dark:hover:bg-zinc-700 rounded-lg"
              }
            >
              <div className="flex items-center">
                All
              </div>
            </div>
          </div>
          {/*
          <div className="bg-zinc-800 mx-1 w-20 flex items-center justify-center rounded-lg cursor-pointer sm:bg-zinc-800 sm:mx-4 sm:w-28 sm:flex items-center sm:justify-center sm:rounded-lg sm:cursor-pointer">
            <div onClick={() => this.props.setType(2)}
              className = {
                this.props.type === 2
                  ?
                  "flex items-center justify-center text-white w-28 h-10 dark:bg-zinc-800 bg-zinc-500 hover:bg-zinc-500 dark:hover:bg-zinc-700 rounded-lg cursor-pointer"
                    :
                  "flex items-center justify-center text-gray-400 w-28 h-10 dark:bg-zinc-800 bg-zinc-500 hover:bg-zinc-500 dark:hover:bg-zinc-700 rounded-lg"
              }
            >
              <div className="flex items-center">
                the402
              </div>
            </div>
          </div>
          */}
          <div className="bg-zinc-800 mx-1 w-20 flex items-center justify-center rounded-lg cursor-pointer sm:bg-zinc-800 sm:mx-4 sm:w-28 sm:flex items-center sm:justify-center sm:rounded-lg sm:cursor-pointer">
            <div onClick={() => this.props.setType(3)}
              className = {
                this.props.type === 3
                  ?
                  "flex items-center justify-center text-white w-28 h-10 dark:bg-zinc-800 bg-zinc-500 hover:bg-zinc-500 dark:hover:bg-zinc-700 rounded-lg cursor-pointer"
                    :
                  "flex items-center justify-center text-gray-400 w-28 h-10 dark:bg-zinc-800 bg-zinc-500 hover:bg-zinc-500 dark:hover:bg-zinc-700 rounded-lg"
              }
            >
              <div className="flex items-center">
                Sound
              </div>
            </div>
          </div>
          <div className="bg-zinc-800 mx-1 w-20 flex items-center justify-center rounded-lg cursor-pointer sm:bg-zinc-800 sm:mx-4 sm:w-28 sm:flex items-center sm:justify-center sm:rounded-lg sm:cursor-pointer">
            <div onClick={() => this.props.setType(1)}
              className = {
                this.props.type === 1
                  ?
                  "flex items-center justify-center text-white w-28 h-10 dark:bg-zinc-800 bg-zinc-500 hover:bg-zinc-500 dark:hover:bg-zinc-700 rounded-lg cursor-pointer"
                    :
                  "flex items-center justify-center text-gray-400 w-28 h-10 dark:bg-zinc-800 bg-zinc-500 hover:bg-zinc-500 dark:hover:bg-zinc-700 rounded-lg"
              }
            >
              <div className="flex items-center">
                Catalog
              </div>
            </div>
          </div>

          <div className="bg-zinc-800 mx-1 w-20 flex items-center justify-center rounded-lg cursor-pointer sm:bg-zinc-800 sm:mx-4 sm:w-28 sm:flex items-center sm:justify-center sm:rounded-lg sm:cursor-pointer">
            <button className="flex leading-3	items-center justify-center text-gray-400 w-28 h-10 dark:bg-zinc-800 bg-zinc-500 hover:bg-zinc-500 dark:hover:bg-zinc-700 rounded-lg" onClick = {this.props.goRetro}>
              Retro mode
            </button>
          </div>

        </div>
        <div className="flex mt-8 p-2 w-10/12 justify-center">
          <div className = "w-1/12 text-zinc-600 dark:text-zinc-400 font-medium text-sm">
            #
          </div>
          <div className = "w-6/12 sm:w-4/12 text-zinc-600 dark:text-zinc-400 font-medium text-sm">
            TITLE
          </div>
          <div className = "w-4/12 sm:w-3/12  text-zinc-600 dark:text-zinc-400 font-medium text-sm">
            ARTISTS
          </div>
          <div className = "invisible w-0 sm:visible sm:w-3/12  sm:text-zinc-600 sm:dark:text-zinc-400 sm:font-medium sm:text-sm">
            OWNER
          </div>
          <div className = "w-1/12 flex justify-end text-zinc-600 dark:text-zinc-400 font-medium text-sm">
            LIKES
          </div>
        </div>
        {
          this.props.isLoading
            ?
          [...Array(15).keys()].map(i => {
            return (
              <div className="animate-pulse w-10/12 flex items-center">
                <div className="mt-2 w-full dark:hover:bg-zinc-700 hover:bg-gray-200 hover:text-gray-800 dark:hover:text-white  text-gray-600 dark:text-gray-400 p-2 rounded-md flex justify-center">
                    <div className = "flex items-center w-1/12 font-medium text-sm">
                      {i + 1}
                    </div>
                    <div className="flex w-6/12 sm:w-4/12 items-center cursor-pointer text-sm">
                      <div className="w-4/12 sm:w-auto flex sm:items-center flex-col justify-center">
                        <div className="platformMusicAlbumArt"></div>
                      </div>
                      <div className = "w-8/12 text-ellipsis overflow-hidden sm:w-auto flex ml-4 items-center cursor-pointer text-sm">
                        Loading
                      </div>
                    </div>
                    <div className = "flex w-4/12 sm:w-3/12 items-center cursor-pointer text-sm">
                    </div>
                    <div className = "invisible sm:visible flex w-0 sm:w-3/12 items-center cursor-pointer text-sm" onClick={this.visitOwner}>
                    </div>
                    <div className = "flex w-1/12 justify-end items-center cursor-pointer text-sm">
                      <IoMdHeartEmpty
                        className="text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
                        size="14px"
                      />
                    </div>
                </div>
              </div>
            );
          })
            :
          null
        }
        <div className="flex flex-col p-2 w-10/12 justify-center">
          {
            this.props.type === 1 || this.props.type === 0
              ?
            this.props.catalog.map(
              (media,idx) => {
                let index = this.props.selectedSongs.map(
                  selectedSong => selectedSong.name + selectedSong.artist + selectedSong.url
                ).indexOf(media.name + media.artist + media.url);
                if(index !== -1) {
                  return (
                    <div key={idx}>
                      <CatalogSong
                        address={this.props.address}
                        onUserClickedConnectWallet={this.props.onUserClickedConnectWallet}
                        media={media}
                        setSong={this.props.setSong}
                        index={index}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              }
            )
              :
            null
          }
          {
            this.props.type === 2 || this.props.type === 0
              ?
            this.props.songs.map(
              (song,idx) => {
                let index = this.props.selectedSongs.map(
                  selectedSong => selectedSong.name + selectedSong.artist + selectedSong.url
                ).indexOf(song.name + song.artist + song.url);
                if(index !== -1) {
                  return (
                    <div key={idx}>
                      <PlatformMusicSectionRow
                        address={this.props.address}
                        onUserClickedConnectWallet={this.props.onUserClickedConnectWallet}
                        song={song}
                        setSong={this.props.setSong}
                        index={index}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              }
            )
              :
            null
          }
          {
            this.props.type === 3 || this.props.type === 0
              ?
            this.props.sounds.map(
              (song,idx) => {
                let index = this.props.selectedSongs.map(
                  selectedSong => selectedSong.name + selectedSong.artist + selectedSong.url
                ).indexOf(song.name + song.artist + song.url);
                if(index !== -1) {
                  return (
                    <div key={idx}>
                      <SoundSong
                        address={this.props.address}
                        onUserClickedConnectWallet={this.props.onUserClickedConnectWallet}
                        song={song}
                        setSong={this.props.setSong}
                        index={index}
                        owners={this.props.soundOwners[song.name]}
                        totalOwners={this.props.soundOwners[song.name].length}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              }
            )
              :
            null
          }
        </div>
        {
          this.props.modalShown === 'createAccount'
            ?
          <Onboarding selectModal={this.props.selectModal} />
            :
          this.props.modalShown === 'addFunds'
            ?
          <AddFunds selectModal={this.props.selectModal} />
            :
          this.props.modalShown === 'importFromPassword'
            ?
          <ImportFromPassword selectModal={this.props.selectModal} />
            :
          null
        }
      </div>
    );
  }
}

export default PlatformMusicSection;
