import './RetroPlatformMusicSection.css';
import RetroPlatformMusicSectionRow from './RetroPlatformMusicSectionRow.js';
import Onboarding from '../Onboarding.js';
import AddFunds from '../AddFunds.js';
import ImportFromPassword from '../ImportFromPassword';
import { FaSpinner } from 'react-icons/fa';
import React, { Component } from 'react';

class RetroPlatformMusicSection extends Component {
  render(){
    return (
      <div className = "retroPlatformMusicRowContainer">
        <div className = "retroPlatformMusicRowTitlesMainContainer">
          <div className = "retroPlatformMusicTitle">
            TITLE
          </div>
          <div className = "retroPlatformMusicArtists">
            ARTISTS
          </div>
          <div className = "retroPlatformMusicLikes">
            LIKES
          </div>
          <div className = "retroPlatformMusicLikes">
            SHARE
          </div>
        </div>
        {
          this.props.isLoading
            ?
          <div className="retroPlatformMusicSpinnerContainer">
            <FaSpinner className="retroPlatformMusicSpinner" />
          </div>
            :
          null
        }
        <div>
          {
            this.props.selectedSongs.map(
              (song,idx) => {
                return (
                  <div key={idx}>
                    <RetroPlatformMusicSectionRow
                      song={song}
                      index={idx}
                      setSong={this.props.setSong}
                      address={this.props.address}
                      onUserClickedConnectWallet={this.props.onUserClickedConnectWallet}
                      playingSong={this.props.playingSong}
                      soundOwners={this.props.soundOwners}
                      glassOwners={this.props.glassOwners}
                    />
                  </div>
                );
              }
            )
          }
        </div>
        {
          this.props.modalShown === 'createAccount'
            ?
          <Onboarding selectModal={this.props.selectModal} />
            :
          this.props.modalShown === 'addFunds'
            ?
          <AddFunds selectModal={this.props.selectModal} />
            :
          this.props.modalShown === 'importFromPassword'
            ?
          <ImportFromPassword selectModal={this.props.selectModal} />
            :
          null
        }
      </div>
    );
  }
}

export default RetroPlatformMusicSection;
