import './ImportFromPassword.css';
import Wallet from './Wallet';
import { IoMdClose } from 'react-icons/io';

function ImportFromPassword(props) {

  const onSubmit = () => {
    let password = document.getElementById("passwordInput").value;
    Wallet.restoreWallet(password);
    props.selectModal("");
  };

  return (
    <div className = "modal-Background">
    <div className="importFromPasswordModal">
      <div className = "importFromPasswordContainer">
        <div className = "importFromPasswordHeaderContainer">
          <IoMdClose onClick={() => props.selectModal("")}
          className="importFromPasswordModalXButton"
          size={24}
          />
        </div>
        <div className = "importFromPasswordHeader">
          Import from password
        </div>
        <div className = "importFromPasswordHeaderSubText">
          Enter password to login with existing wallet
        </div>
        <div className = "importFromPasswordFormMainContainer">
          <form className = "importFromPasswordFormContainer">
            <input id = "passwordInput" className = "passwordInput" type="password" name="password" placeholder = "Enter password" />
          </form>
        </div>
        <div className = "importFromPasswordSubmitButton" onClick={() => onSubmit()}>
          <input className = "importFromPasswordSubmit" type="submit" value="Continue" />
        </div>
      </div>
    </div>
    </div>
  );
}

export default ImportFromPassword;
