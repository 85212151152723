import './WelcomeModal.css';
import { IoMdClose } from 'react-icons/io';
import the402logo from './the402logo.png';

function WelcomeModal(props) {
  return (
    <div className = "modal-Background">
    <div className="welcomeModal">
      <div className = "welcomeModalContainer">
        <div className = "welcomeModalHeaderContainer">
        <div className = "welcomeModalXButtonContainer">
          <IoMdClose onClick={() => props.selectModal("")}
          className="welcomeModalXButton"
          size={24}
          />
        </div>
        </div>
        <div className = "welcomeModalWalletSelctionContainer">
        <div className = "welcomeModalLogoAndDescriptionContainer">
          <img className = "the402logo" src = {the402logo} />
          <div className = "walletText">
             Your cryptomedia media wallet
          </div>
        </div>
        <div className ="welcomeModalWalletOptionsContainer">
          <div className = "welcomeModalWalletNewWalletButton" onClick={() => props.updateStep(props.onboardingSteps.NEW_SEED)}>
            <div className = "welcomeModalWalletText">
              Create a new wallet
            </div>
          </div>
          <div className = "welcomeModalWalletImportWalletButton" onClick={() => props.updateStep(props.onboardingSteps.IMPORT_SEED)}>
            <div className = "welcomeModalWalletText">
              I already have a wallet
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default WelcomeModal;
