import './RetroPlatformMusicCoverPhoto.css';

function RetroPlatformMusicCoverPhoto(props) {
  return (
    <div className = "retroPlatformMusicCoverPhotoContainer">
      <div className = "retroSelectedItem">
        NOW PLAYING
      </div>
      <div className="retroPlatformMusicCoverPhotoInnerContainer">
        {
          props.song.image != null
            ?
          <img className="musicCoverPhoto" src={props.song.image} alt="cover"/>
            :
          <div className="musicCoverPhotoEmpty"></div>
        }
      </div>
    </div>
  );
}

export default RetroPlatformMusicCoverPhoto;
