import './PlatformSidebarMenuItems.css';
import { useHistory, useLocation } from "react-router-dom";
import the402logo from './the402logo.png';

function PlatformSidebarMenuItems(props) {
  const history = useHistory();
  const location = useLocation();
  function handleClickMarketplace() {
    history.push("/marketplace")
  }
  function handleClickVideos() {
    history.push("/videos")
  }
  function handleClickMusic() {
    history.push("/")
  }
  function handleClickUpload() {
    history.push("/upload")
  }
  return (
    <div className="flex items-center">
       {/*
      <div className = {location.pathname === "/videos" || location.pathname === "/videos/video" ? "ml-2 text-xs sm:text-base sm:ml-10 font-medium text-gray-600 dark:text-gray-400 cursor-pointer" : "ml-2 sm:ml-10 text-xs sm:text-base font-medium text-gray-600 dark:text-gray-400 cursor-pointer"} onClick = {handleClickVideos}>
        <div>
          Videos
        </div>
      </div>
      */}
      <div className = {location.pathname === "/" ? "ml-2 sm:ml-10 text-xs sm:text-base font-medium text-gray-600 dark:text-gray-400 cursor-pointer" : "ml-2 sm:ml-10 text-xs sm:text-base font-medium text-gray-600 dark:text-gray-400 cursor-pointer"} onClick = {handleClickMusic}>
        <div>
          Music
        </div>
      </div>
      {/*
      <div className = {location.pathname === "/marketplace" || location.pathname === "/marketplace/auction" || location.pathname.includes("/mint/") ? "ml-2 sm:ml-10 text-xs sm:text-base font-medium text-gray-600 dark:text-gray-400 cursor-pointer" : "ml-2 sm:ml-10 text-xs sm:text-base font-medium text-gray-600 dark:text-gray-400 cursor-pointer"} onClick = {handleClickMarketplace}>
        <div>
          Marketplace
        </div>
      </div>
      */}
       {/*
      <div className = {location.pathname === "/" ? "text-xs sm:text-base bg-black dark:bg-white h-10 w-16 sm:w-24 justify-center items-center rounded-lg flex ml-2 sm:ml-10 font-medium text-white dark:text-black cursor-pointer" : "text-xs sm:text-base bg-black dark:bg-white h-10 w-16 sm:w-24 justify-center items-center rounded-lg flex ml-2 sm:ml-10 font-medium text-white dark:text-black cursor-pointer"} onClick = {handleClickUpload}>
        <div>
          Upload
        </div>
      </div>
        */}
    </div>
  );
}

export default PlatformSidebarMenuItems;
