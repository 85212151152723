import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from './ThemeContext';
import Background from './Background';
import Toggle from './ThemeToggle';


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <Background>
         <div className="fixed right-0 bottom-0 mr-4 mb-4 md:mr-6 md:mt-6">
            <Toggle />
          </div>
        <App/>
      </Background>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
