import React, { Component } from 'react';
import './PlatformMusicPlayer.css';
import { IoPlaySkipForwardSharp, IoPlaySharp, IoPauseSharp, IoPlaySkipBackSharp } from "react-icons/io5";
import * as encryptUtils from './encryptDecryptUtils';
import * as loggingUtils from './utils/loggingUtils';

class PlatformMusicPlayer extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.onPlaying = this.onPlaying.bind(this);
    this.onEnded = this.onEnded.bind(this);
    this.audioPlayer = React.createRef();

    this.state = {
      seekValue: 0,
      totalListenTime: 0,
      currentTime: "00.00",
      remainingTime: "00.00",
      playing: true
    }
  }

  async play() {
    if(this.props.address == null) {
      this.props.onUserClickedConnectWallet();
    } else if(!this.audioPlayer.current.paused){
      this.audioPlayer.current.pause();
    } else{
      if(this.props.song.tokenId != null && this.props.song.url == null) {
        await this.props.litDecrypt();
      }
      this.audioPlayer.current.play();
    }

    if(this.props.updatePlayingStatus) {
      this.props.updatePlayingStatus();
    }

    let playState = !this.audioPlayer.current.paused;
    this.setState({playing: playState});
  }

  onPlaying() {
    if(this.props.song.url !== ""){
      let totalListenTime = 0;
      for(let i = 0; i < this.audioPlayer.current.played.length; i++) {
        totalListenTime += this.audioPlayer.current.played.end(i) - this.audioPlayer.current.played.start(i)
      }
      if(totalListenTime > 30 && this.state.totalListenTime < 30) {
        if(this.props.address != null) {
          encryptUtils.logImpression(this.props.song.tokenCID, this.props.address);
          loggingUtils.logEvent(
            this.props.address != null ? this.props.address : "wallet_unconnected_user",
            'music_player',
            'music_player_log_impression',
            'info',
            "https://the402.xyz",
            {
              song: this.props.song,
            },
          );
        }
      }
      this.setState({totalListenTime});
      let currentSeconds = parseFloat(this.audioPlayer.current.currentTime).toFixed(2);
      let current = new Date(currentSeconds * 1000).toISOString().substr(14, 5);
      let remainingSeconds = parseFloat(this.audioPlayer.current.duration - currentSeconds).toFixed(2);
      let remaining = "";
      let seekValue = 0;
      if (isNaN(remainingSeconds)){
        remaining = "00.00"
      }else{
        remaining = new Date(remainingSeconds * 1000).toISOString().substr(14, 5);
        seekValue =  (this.audioPlayer.current.currentTime / this.audioPlayer.current.duration) * 100;
      }
      this.setState({currentTime: current, remainingTime: remaining, seekValue});
    }
  }

  onEnded() {
    this.props.revokeAccess();
    loggingUtils.logEvent(
      this.props.address != null ? this.props.address : "wallet_unconnected_user",
      'music_player',
      'music_player_song_ended',
      'info',
      "https://the402.xyz",
      {
        song: this.props.song,
      },
    );
    this.setState({totalListenTime: 0});
  }

  render() {
    return (
      <div className="platformMusicContainer">
        <div className="flex pl-6 w-2/12 sm:w-2/12 items-center">
          <div className="w-20 h-20">
            {
              this.props.song.image != null
                ?
              <img class="w-20 h-20 max-w-none" src={this.props.song.image} alt="cover"/>
                :
              <div className="platformMusicPlayer-AlbumArtwork"></div>
            }
          </div>
          <div className="flex flex-col w-full ml-6">
            <div className="platformMusicPlayerSongName">
              {this.props.song.name}
            </div>
            <div className="platformMusicPlayerArtistName">
              {this.props.song.artist}
            </div>
          </div>
        </div>
        <div className="flex justify-end w-8/12 sm:w-8/12 sm:justify-center sm:items-center">
        <div className="w-6/12 flex-col">
          <audio
            src={this.props.song.url}
            ref={this.audioPlayer}
            onTimeUpdate={this.onPlaying}
            autoPlay
            onEnded={this.onEnded}
          >
            Your browser does not support the
            <code>audio</code> element.
          </audio>
          {
            this.props.song.url !== ""
              ?
            <div className="platformMusicPlayer-buttons">
              <IoPlaySkipBackSharp className="platformMusicPlayer-backButton" onClick={() => this.props.backSong()} />
              <div className="platformMusicPlayer-playButtonBackground" onClick={async () => await this.play()}>
                {
                  this.audioPlayer.current == null || this.audioPlayer.current.paused
                    ?
                  <IoPlaySharp className="platformMusicPlayer-playButton"/>
                    :
                  <IoPauseSharp className="platformMusicPlayer-pauseButton"/>
                }
              </div>
              <IoPlaySkipForwardSharp className="platformMusicPlayer-forwardButton" onClick={() => this.props.nextSong()} />
            </div>
              :
              <div className="platformMusicPlayer-buttons">
              <IoPlaySkipBackSharp className="platformMusicPlayer-backButton" onClick={() => this.props.backSong()} />
              <div className="platformMusicPlayer-playButtonBackground" onClick={async () => await this.play()}>
                <IoPlaySharp className="platformMusicPlayer-playButton"/>
              </div>
              <IoPlaySkipForwardSharp className="platformMusicPlayer-forwardButton" onClick={() => this.props.nextSong()} />
            </div>
          }
          <div className="platformMusicPlayerBoxContainer">
            {
              this.props.song.url !== ""
                ?
              <div className="platformMusicPlayer-scrollbarInfo">
                <div className="platformMusicPlayer-currentTime">
                  {this.state.currentTime}
                </div>
                <input className="platformMusicPlayer-scrollbar"
                  type="range"
                  min="0"
                  max="100"
                  step="1"
                  value={this.state.seekValue}
                  onChange={(e) => {
                    const seekto = this.audioPlayer.current.duration * (+e.target.value / 100);
                    this.audioPlayer.current.currentTime = seekto;
                    this.setState({ seekValue: (e.target.value) });
                  }}
                />
                <div className="platformMusicPlayer-remainingTime">
                  {this.state.remainingTime}
                </div>
              </div>
                :
                <div className="platformMusicPlayer-scrollbarInfo">
                <div className="platformMusicPlayer-currentTime">
                  {this.state.currentTime}
                </div>
                <input className="platformMusicPlayer-scrollbar"
                  type="range"
                  min="0"
                  max="100"
                  step="1"
                  value={this.state.seekValue}
                  onChange={(e) => {
                    const seekto = this.audioPlayer.current.duration * (+e.target.value / 100);
                    this.audioPlayer.current.currentTime = seekto;
                    this.setState({ seekValue: (e.target.value) });
                  }}
                />
                <div className="platformMusicPlayer-remainingTime">
                  {this.state.remainingTime}
                </div>
              </div>
            }
          </div>
        </div>
        </div>
        <div className="flex sm:pr-6 sm:w-2/12 w-0 items-center">

        </div>
      </div>
    );
  }
}

export default PlatformMusicPlayer;
