import './RetroUI/RetroPlatformMusicSectionRow.css';
import './SoundSong.css';
import { IoMdClose } from 'react-icons/io';
import React, { Component } from 'react';
import { IoMdHeartEmpty, IoMdHeart, IoIosCheckmarkCircle } from 'react-icons/io';
import * as encryptUtils from './encryptDecryptUtils';
import * as loggingUtils from './utils/loggingUtils';

class SoundSong extends Component {
  constructor(props) {
    super(props);
    this.hideFullOwners = this.hideFullOwners.bind(this);
    this.showFullOwners = this.showFullOwners.bind(this);
    this.onClickHeart = this.onClickHeart.bind(this);
    this.state = {
      isShowingFullOwners: false,
      isHeartClicked: this.props.song.userVote,
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.song.userVote !== this.props.song.userVote) {
      this.setState({
        isHeartClicked: this.props.song.userVote,
      });
    }
  }

  async onClickHeart() {
    if(this.props.address == null) {
      this.props.onUserClickedConnectWallet();
    } else {
      if(!this.props.song.userVote) {
        await encryptUtils.logUpvote(this.props.song.tokenCID, this.props.address);
        loggingUtils.logEvent(
          this.props.address,
          'music_player',
          'music_player_upvote_song',
          'info',
          "https://the402.xyz",
          {
            song: this.props.song,
          },
        );
        this.props.song.userVote = true;
        this.props.song.totalUpvotes += 1;
        this.setState({
          isHeartClicked: true,
        });
      } else {
        await encryptUtils.logRemoveUpvote(this.props.song.tokenCID, this.props.address);
        loggingUtils.logEvent(
          this.props.address,
          'music_player',
          'music_player_remove_upvote_song',
          'info',
          "https://the402.xyz",
          {
            song: this.props.song,
          },
        );
        this.props.song.userVote = false;
        this.props.song.totalUpvotes -= 1;
        this.setState({
          isHeartClicked: false,
        });
      }
    }
  }

  showFullOwners(e) {
    e.stopPropagation();
    this.setState({
      isShowingFullOwners: true,
    });
  }

  hideFullOwners() {
    this.setState({
      isShowingFullOwners: false,
    });
  }

  render(){
    let songName = this.props.song.name;
    let displayedOwner = this.props.owners[0];
    if(displayedOwner.includes("0x")) {
      displayedOwner = displayedOwner.substring(0,5) + "..." + displayedOwner.substring(displayedOwner.length - 5);
    }
    return (
      <div className="flex items-center" onClick={this.hideFullOwners}>
        <div className="mt-2 w-full dark:hover:bg-zinc-700 hover:bg-gray-200 hover:text-gray-800 dark:hover:text-white  text-gray-600 dark:text-gray-400 p-2 rounded-md flex justify-center" onClick={
          () => {
            if(this.props.address == null) {
              this.props.onUserClickedConnectWallet();
            } else {
              this.props.setSong(this.props.song, this.props.index);
            }
          }
        }>
            <div className = "flex items-center w-1/12 font-medium text-sm">
              {this.props.index + 1}
            </div>
            <div className="flex w-6/12 sm:w-4/12 items-center cursor-pointer text-sm">
              <div className="w-4/12 sm:w-auto flex sm:items-center flex-col justify-center">
                {
                  this.props.song.image != null
                    ?
                  <img className="platformMusicAlbumArt" src={this.props.song.image} alt="cover"/>
                    :
                  <div className="platformMusicAlbumArt"></div>
                }
              </div>
              <div className = "w-8/12 text-ellipsis overflow-hidden sm:w-auto flex ml-4 items-center cursor-pointer text-sm">
                {songName}
              </div>
            </div>
            <div className = "flex w-4/12 sm:w-3/12 items-center cursor-pointer text-sm">
              {this.props.song.artist}
            </div>
            <div className = "invisible sm:visible flex w-0 sm:w-3/12 items-center cursor-pointer text-sm" onClick={this.visitOwner}>
              {displayedOwner} + {this.props.totalOwners - 1} additional owners
            </div>
            <div className = "flex w-1/12 justify-end items-center cursor-pointer text-sm">
              {
                this.state.isHeartClicked
                  ?
                <IoMdHeart
                  className="text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
                  onClick={async (e) => {
                    e.stopPropagation();
                    await this.onClickHeart();
                  }}
                  color="red"
                  size="14px"
                />
                  :
                <IoMdHeartEmpty
                  className="text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
                  onClick={async (e) => {
                    e.stopPropagation();
                    await this.onClickHeart();
                  }}
                  size="14px"
                />
              }
            </div>
        </div>
        {
          this.state.isShowingFullOwners
            ?
          <div className="platformMusicFullOwnersContainer" >
            <div className="platformMusicFullOwners">
            <div className = "platformMusicFullOwners-Header">
              <div className = "platformMusicFullOwners-SongName"> Owners of {songName} </div>
              <IoMdClose onClick={this.hideFullOwners}
                size={24}
                className="platformMusicFullOwnersXButton"
              />
            </div>
              {
                this.props.owners.map((owner, ind) => {
                  return (
                    <a className="platformMusicOwner" href={"https://opensea.io/" + owner} key={ind} target="_blank" rel="noreferrer">
                      {owner}
                    </a>
                  );
                })
              }
            </div>
          </div>
            :
          null
        }
      </div>
    );
  }
}

export default SoundSong;
