import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Platform from "./Platform";
import { useAccount } from "wagmi";

function AppWithRainbow(props) {
  const { data: account } = useAccount();
  return (
    <Router>
      <Switch>
        <Route
          exact path="/"
          render={(props) => (
            <Platform account={account} initiallyRetro={false} />
          )}
        />
        <Route
          exact path="/:songDetails"
          render={(props) => (
            <Platform account={account} initiallyRetro={false} />
          )}
        />
      </Switch>
    </Router>
  );
}

export default AppWithRainbow;
