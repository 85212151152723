import './AddFunds.css';
import React, { Component } from 'react';
import { IoMdClose } from 'react-icons/io';
import QRCodeComponent from './QRCodeComponent';
import Wallet from './Wallet';
import { BiCopy } from "react-icons/bi";

class AddFunds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: '',
    }
  }

  componentDidMount() {
    let address = Wallet.address();
    this.setState({
      address,
    });
  }

  onClickOnRamper() {
    window.location.href = "http://localhost:3000/onramp/";
  }

  render() {
    let address = this.state.address;
    return (
      address != null && address !== ''
        ?
      <div className = "modal-Background">
      <div className="addFundsModal">
        <div className = "addFundsContainer">
          <div className = "addFundsHeaderContainer">
            <IoMdClose onClick={() => this.props.selectModal("")}
            className="addFundsXButton"
            size={24}
          />
          </div>
          <div className = "addFundsHeader">
            Add funds to your wallet
          </div>
          <div className = "addFundsHeaderSubText">
            Deposit from any crypto wallet
          </div>
          <div className = "QRcodeMainContainer">
            <QRCodeComponent publicKey = {address} />
          </div>
          <div className = "addFundsAddressContainer">
            <div className = "addFundsSubmit">
              <div className = "publicKeyContainer">
                {address.substr(0, 7) + '...' + address.substr(address.length - 7, address.length)}
              </div>
            <BiCopy className = "copyIcon"/>
            </div>
            <div className = "sentMoneyButton" onClick = {this.onClickOnRamper} >
              <div className = "sentMoneyButtonText">
                Pay using credit card
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
        :
      null
    );
  }
}

export default AddFunds;
